// Define primary and secondary colors
$color-primary: #3AA431; // Green
$color-secondary: #ffffff; // White

:root {
  --ion-color-primary: #{$color-primary};
  --ion-color-primary-rgb: 58, 164, 49; // RGB equivalent for transparency
  --ion-color-secondary: #{$color-secondary};
  --ion-color-secondary-rgb: 255, 255, 255;
  
  --ion-color-primary-contrast: #ffffff; // White text on green background
  --ion-color-primary-shade: #34982a;    // Slightly darker green for shadows
  --ion-color-primary-tint: #56c54a;     // Slightly lighter green for highlights

  // Ensure background and text colors are light theme defaults
  --ion-background-color: #ffffff; // White background
  --ion-text-color: #000000; // Black text
}
